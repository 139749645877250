/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import Img from 'gatsby-image/withIEPolyfill';
import { useStaticQuery, graphql } from 'gatsby';
import Container from './Container';
import Layout from './layout';
import { isAppleLoginEnabled } from '../utils';

export default props => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "login.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    //    !user && (
    <Layout {...props} showNavigation={false} footer={false}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: ['calc(100vh - 80px)', 'calc(100vh - 96px)'],
        }}
      >
        <Box
          sx={{
            position: ['relative', 'absolute'],
            top: 0,
            left: 0,
            width: '100%',
            height: ['auto', '100%'],
            maxHeight: [200, 'none'],
          }}
        >
          <Img
            fluid={data.bg.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            sx={{
              width: '100%',
              height: '100%',
              zIndex: 0,
              bg: 'secondary',
              /*
              img: {
                mixBlendMode: 'screen',
              }, // */
            }}
          />
        </Box>
        <Container
          sx={{
            position: 'relative',
            zIndex: 1,
            maxWidth: isAppleLoginEnabled ? 640 : 480,
            p: [3, 4],
            bg: 'white',
          }}
        >
          {props.children}
        </Container>
      </Box>
    </Layout>
  );
};
